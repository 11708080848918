<template>
  <div class='process-in-action-container'>

    <div class='procedure-steps'>
      <div class='procedure-steps__header'>
        <a href='#' @click.prevent.stop='submitForm' class='button button--primary button--large'><i class="fas fa-save"></i>Save Changes</a>
      </div>

      <div class='procedure-steps__steps procedure-steps__steps--admin'>
        <div  :class='{"procedure-steps__step": true,"procedure-steps__step--active": openProcessEdit == -1}'>
          <div></div><h1><a href='#' @click.prevent.stop='toggleOpenProcessEdit(-1)'>Change Process Basics</a></h1>
        </div>
        <div class='procedure-steps__title'>Change Tasks:</div>
        <draggable v-model="procedure_tasks_attributes" @end="onSortEnd">
          <transition-group>
            <div  :class='{"procedure-steps__step": true,"procedure-steps__step--active": index == openProcessEdit}' :key="'drag' + index" v-show='!task._destroy' v-for='(task, index) in procedure_tasks_attributes'>
              <i class="fas fa-ellipsis-v"></i>
              <h1><a href='#' @click.prevent.stop='toggleOpenProcessEdit(index)'>{{task.name ? task.name : "Untitled Task"}}</a></h1>
              <a @click.prevent.stop='removeTask(index, task.id)' href='#'><i class="fas fa-trash danger"></i></a>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class='procedure-steps__footer'>
        <a href='#' @click.prevent.stop="addTask" class='button button--large button--success'><i class='fas fa-plus'></i>New Task</a>
      </div>
    </div>
    <form @submit.prevent.stop="submitForm">

      <div class='process-in-action__form' v-if='!openEdit'>
        <div class='form-group'>
          <label for='procedure_name' class='required'>Process Name</label>
          <input type='text' required id='procedure_name' v-model='name' placeholder='Our Standard Employee Termination Procedure'>
        </div>

        <div class='form-group'>
          <label for='procedure_description'>Process Description</label>
          <textarea v-model='description' placeholder="Describe what will be accomplished at the end..."></textarea>
        </div>
      </div>

      <div class='process-in-action__form' v-if='openEdit'>
        <div class='form-group'>
          <label class='required' :for='"task" + openProcessEdit' placeholder=''>Task Name</label>
          <input type='text' :id='"task" + openEdit.id' required :value='openEdit.name' @input='e => updateTaskName(openProcessEdit, e)'>
        </div>
        <div class='form-group'>
          <label>Description of Task</label>
          <process-writer :dark_mode='dark_mode' v-if='openEdit' :record_id='id' record_type='Procedure' :description="openEdit.description"></process-writer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import ProcessWriter from './Writer.vue';

export default {
  props: ["url", "can", "turn_off_completion", "id", "rate", "percentage", "dark_mode"],
  components: {
    draggable,
    ProcessWriter
  },
  computed: {
    ...mapState("procedures", {
      'procedure': 'procedure',
      'openProcessEdit': 'openProcessEdit'
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength',
      'openEdit': 'openEdit'
    }),
    name: {
      get() {
        return this.$store.state.procedures.procedure.name
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureName', value)
      }
    },
    description: {
      get() {
        return this.$store.state.procedures.procedure.description
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureDescription', value)
      }
    },
    bulk: {
      get() {
        return this.$store.state.procedures.bulk
      },
      set(value) {
        this.$store.dispatch('procedures/updateBulk', value)
      }
    },
    procedure_tasks_attributes: {
      get() {
        return this.procedure.procedure_tasks_attributes
      },
      set(value) {
        this.$store.dispatch('procedures/update_tasks', value)
      }
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('procedures/submitForm');
    },
    updateTaskName(index, e) {
      this.$store.dispatch('procedures/updateTaskName', [index, e.target.value]);
    },
    onSortEnd () {
      this.$store.dispatch('procedures/resort');
    },
    toggleEdit () {
      this.$store.dispatch('procedures/toggleEdit');
    },
    toggleOpenProcessEdit (index) {
      this.$store.dispatch('procedures/toggleOpenProcessEdit', index);
    },
    addTask () {
      this.$store.dispatch('procedures/addTask');
      this.flash("New Task Added!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
    },
    removeTask (index, id) {
      this.$store.dispatch('procedures/removeTask', [index, id]);
    }
  }
}
</script>