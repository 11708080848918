<template>
  <div class='procedure-editor'>
    <flash-message></flash-message>
    <div class='procedure__viewer'>
      <div class='procedure__content'>

        <article v-if='showDescription && !showEdit' class='procedure__description ck-content' v-html="procedure.description"></article>

        <process-toolbar :can='can' :turn_off_completion='turn_off_completion'  />
        <process-admin v-if='showEdit' :dark_mode='dark_mode' />
        <process-view v-if='!showEdit' :turn_off_completion='turn_off_completion' />
        
      </div>
    </div>
   
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex'
import ProcessToolbar from './processor/Toolbar.vue';
import ProcessAdmin from './processor/Admin.vue';
import ProcessView from './processor/View.vue'

export default {
  props: ["url", "can", "turn_off_completion", "id", "dark_mode"],
  components: {
    ProcessToolbar,
    ProcessAdmin,
    ProcessView
  },
  computed: {
    ...mapState("procedures", {
      'showEdit':  'showEdit',
      'procedure': 'procedure',
      'showDescription': 'showDescription'
    })
  },
  mounted () {
    this.$store.dispatch('procedures/setState', [this.url, this.can, this.template])
    this.$store.dispatch('procedures/fetch');
  },
  methods: {
    
  }
}
</script>

