<template>
  <div style='position: relative;'>
    <a href='#' class='button button--secondary button--security' @click.prevent.stop="isShown = true"><i class='fas fa-lock'></i>Change Group Access</a>
    <div class='duplicator' v-if='isShown'>
      <span><b>Change Group Access</b></span>
      <div class='button-group'>
        <slot></slot>
        <a href='#' class='button button--secondary' @click.prevent.stop="isShown = false">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [""],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

