<template>
  <div class='pinner'>
    <a title='Favorite' href='#' v-if='!pinned' @click.prevent.stop='pin'><i class='fas fa-heart'></i></a>
    <a title='Favorite' href='#' class='pinned' v-if='pinned' @click.prevent.stop='pin'><i class='fas fa-heart'></i></a>
  </div>
</template>

<script>

export default {
  props: ["pins_path", "unpin_path", "already_pinned"],
  data () {
    return {
      pinned: this.already_pinned
    }
  },
  methods: {
    pin () {
      var that = this;
      if (that.pinned) {
        that.$api.delete(that.unpin_path)
        .then(function (response) {
          that.pinned = false;
          that.flash("Removed from Favorites!", 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        })
      } else {
        that.$api.post(that.pins_path)
        .then(function (response) {
          that.pinned = true;
          that.flash("Added to Favorites!", 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        })
      }
    }
  },

}
</script>

