<template>
  <div>

    <div>
      <div style='display: inline-block'>
        <a href='#' v-show="changePassword != true" class='button button--secondary' @click.prevent="changePassword = true">Change password</a>
      </div>

      <div v-show='changePassword' class='inputer the-password-form' style='margin-top: -1rem'>
        <input autocomplete="new-password" type='password' :id="id" v-model='password' ref='password'>
        <input type='hidden' ref="password_hidden" :name='name' value=''>
        <aside>
          <a href='#' @click.prevent="viewPassword"><i class='fas fa-eye'></i></a>
          <a href='#' @click.prevent="isShown = !isShown"><i class="fas fa-magic"></i>Generate</a>
        </aside>
      </div>
    </div>

    <div v-show="isShown" class='password-editor'>
        <div class='password-editor__content'>
          <div class='form-group'>
            <label>Generated Password:</label>
            <input type='text' class='password-editor__generated' readonly v-model="generatedPassword">
          </div>

          <div class='form-group form-group-radios'>
            <label>
             <input @change="generate" type="radio" value="0123456789![]{}()%&*$#^<>~@|abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ" v-model="possible">All characters
            </label>
            <label>
             <input @change="generate" type="radio" value="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ" v-model="possible">Easier to say (removes special characters and numbers)
            </label>
            <label>
             <input @change="generate" type="radio" value="abcdefghkmnoprstwxzABCDEFGHJKLMNPQRTWXY34689" v-model="possible">Easier to read (removes look-alike characters)
            </label>
            <label>
             <input @change="generate" type="radio" value="word" v-model="possible">Easier to remember (word-based)
            </label>
          </div>
          <div class='form-group' v-if='possible != "word"'>
            <label>Length</label>
            <div class='password-length'>
              <input @input="generate" type='number' min="1" max="50" v-model="length" >
              <input @change="generate" type="range" min="1" max="50" v-model="length" class="slider">
            </div>
          </div>
          <div class='form-group' v-if='possible == "word"'>
            <label>Number of Words</label>
            <div class='password-length'>
              <input @input="generate" type='number' min="3" max="15" v-model="numWords" >
              <input @change="generate" type="range" min="3" max="15" v-model="numWords" class="slider">
            </div>
          </div>
          <div class='form-group form-group-radios' v-if='possible == "word"'>
            <label>
              <input @change="generate" type="checkbox" v-model="useAlternatingCase">Use Alternating Case Between Words
            </label>
            <label>
              <input @change="generate" type="checkbox" v-model="includeNumber">Include Number
            </label>
            <label>
              <input @input="generate" style='text-align: center;font-size: 2rem' type="text" v-model="separator">Word Separator
            </label>
            
          </div>
          <div class='button-group'>
            <a href='#' @click.prevent="fill" class='button button--primary'><i class='fas fa-key'></i>Fill Password</a>
            <a href='#' @click.prevent="generate" class='button button--secondary'><i class='fas fa-redo'></i>Refresh</a>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import list from "../lib/word-list.js";

export default {
  name: "password-editor",
  props: ["show_button", "name", "id", "value", "prelength"],
  mounted () {
    if (this.value) {
      this.password = this.value;
    }
    var that = this;
    that.generate()
  },
  watch: {
    password: function (newPassword, oldPassword) {
      this.$refs.password_hidden.value = newPassword;
    }
  },
  methods: {
    viewPassword () {
      if ( this.$refs.password.type == "password") {
        this.$refs.password.type = "text"
      } else {
        this.$refs.password.type = "password"
      }
    },
    fill () {
      this.password = this.generatedPassword;
      this.isShown = false;
    },
    generate () {
      var that = this;
      var i, possible, text;
      var wordArray = []

      text = "";

      if (that.possible == "word") {
        i = 0;
        while (i < that.numWords ) {

          var chosen = that.chooseRandomWord()

          if (i % 2 == 0 && that.useAlternatingCase) {
            chosen = chosen.toUpperCase();
          }

          wordArray.push(chosen)
          i++;
        }
        text = wordArray.join(that.separator)
        if (that.includeNumber) {
          text = text + (Math.floor(Math.random() * 10));
        }
      } else {
        i = 0;
        while (i < that.length ) {
          text += that.possible.charAt(Math.floor(Math.random() * that.possible.length));
          i++;
        }
      }
      that.generatedPassword = text
    },
    chooseRandomWord() {
      var start = 0
      var finish = list.names.length - 1

      return list.names[Math.floor(Math.random() * finish)].replace(/\s/g, '').toLowerCase();
    }
  },

  data: function () {
    return {
      password: "",
      length: this.prelength,
      numWords: 4,
      separator: "-",
      includeNumber: false,
      useAlternatingCase: false,
      generatedPassword: "",
      changePassword: !this.show_button,
      isShown: false,
      possible: "0123456789![]{}()%&*$#^<>~@|abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    }
  }
}
</script>

