<template>
  <div class='writer-wrap'>
    <textarea :name='name' :id="'editor' + id">{{precontent}}</textarea>
  </div>
</template>

<script>

import tinymce from 'tinymce';
import initTiny from "../lib/tinymce-editor.js";

export default {
  props: ["precontent", "name", "value", "simple", "id", "record_id", "record_type", "dark_mode"],
  components: {
    
  },
  mounted () {
    var that = this;
    initTiny(that, `#editor${that.id}`, null, this.dark_mode)
  },
  data() {
    return {
    }
  }
}
</script>

<style lang='scss'>
</style>

