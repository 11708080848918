<template>
  <div>
    <flash-message></flash-message>
    <div class='drafter__major-error'>
      <div class='drafter__major-error__box' v-if='majorError'>
        <p><i class='fal fa-exclamation-triangle'></i> An error occurred saving this draft. Please copy the following HTML to an <b>external editor</b> to prevent losing unpublished changes.</p>
        <textarea :value='html_content' style='height: 150px' readonly></textarea>
        <a href='#' class='button--primary button' @click.prevent.stop='save'><i class='fas fa-redo'></i>Re-attempt Save</a>
      </div>
    </div>
    <div class='drafter'>
      <main>
        <div class='drafter__titlebar'>
          <input v-model='title' @input='isDirty = true' required='true' type='text' id='articleTitle' placeholder='How to...' class='drafter__title'>
        </div>
        <div class='drafter__content'>
          <div class='writer-wrap writer--article'>
            <input type='hidden' :value='title' name='article[name]'>
            <div class='drafter__writer'>
              <div class='form-group'>
                <textarea name='article[content]' id="mytextarea">{{precontent}}</textarea>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div class='drafter__finish'>
        <div class='drafter__autosave'>
          <h3 v-if='restored && has_draft'><a href='#'  @click.prevent.stop='unrestore'>Restored from draft - revert to live version</a></h3>
          <h3 v-if='last_saved'><b>Last autosaved:</b> <span class='autosaved-date'>{{last_saved}}</span><a @click.prevent.stop='save' href='#' v-if='isDirty'> (Save Now)</a></h3>
          <h3 v-if='!last_saved'><b>Last autosaved:</b> <span class='autosaved-date'>{{last_updated}}</span><a @click.prevent.stop='save' href='#' v-if='isDirty'> (Save Now)</a></h3>
        </div>
        <header class='drafter__finish__header'>
            <div class='drafter__publish'>
            <a @click.prevent.stop='publish' class='button button--lg button--primary' href='#'><i class="fas fa-pen-alt"></i>Publish</a>
          </div>
          <a class='button button--secondary' :href='article_url'><i class="fas fa-long-arrow-alt-left"></i>Cancel</a>
        </header>
        <main>
          <folder-chooser v-on:changeFolder="folderWasChanged" name='article[folder_id]' 
          :existing_name="folder_existing_name"
          :value='folder_value' 
          :folders='folders'></folder-chooser>
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
  
</template>

<script>
import FolderChooser from './folder_chooser.vue';


/* Import TinyMCE */
import tinymce from 'tinymce';
import initTiny from "../lib/tinymce-editor.js";

export default {

  props: ["precontent", "prename", "name", "value",
   "simple", "id", "autosave_url", "has_draft", 
   "draft_title", "draft_content",  "last_saved", "last_updated",
   "article_url", "folder_existing_name", "draft_folder_existing_name", "draft_folder_value", 
   "folders", "folder_value", "dark_mode"],
  components: {
    FolderChooser
  },
  mounted () {
    document.title = this.title.length > 0 ? this.title : "Start writing..."
    var that = this;

    initTiny(that, "#mytextarea", 600, this.dark_mode)

    if (that.has_draft) {
      that.restore()
      that.restored = true
    }

    setInterval(function(){ 
      that.save();
    }, that.autosave_seconds);
  },
  watch: {
    title: function (val) {
      document.title = val.length > 0 ? val : "Start writing..."
      this.allowSave = true
    },
    content: function (val) {
      this.allowSave = true
    }
  },
  methods: {
    publish () {
      this.isDirty = false
      document.querySelector("#draftForm").submit()
    },
    restore () {
      this.restored = true;
      this.title = this.draft_title;
      tinymce.get("mytextarea").setContent(this.draft_content);
      this.isDirty = false
    },
    folderWasChanged () {
      this.isDirty = true;
    },
    unrestore () {
      if(confirm('Are you sure?')) {
        this.restored = false;
        this.title = this.prename;
        tinymce.get("mytextarea").setContent(this.precontent);
        this.isDirty = false
        this.useExistingFolder = true
        this.folderValue = this.folder_value;
        this.folderName = this.folder_existing_name;
      }
    },
    save () {
      var that = this;
      that.$api.put(that.autosave_url, { 
        "title" : that.title,
        "content": tinymce.get("mytextarea").getContent(),
        "folder_id": document.querySelector("#folderIdInput").value
      }).then(function (response) {
        that.isDirty = false;
        that.html_content = "";
        that.majorError = false;
        var date = response.data.date;
        document.querySelector(".autosaved-date").innerText = date;
        that.flash("Saved!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      }).catch(error => {
        that.majorError = true
        that.html_content = tinymce.get("mytextarea").getContent();
        console.log(error)
        that.flash("Something went wrong!", 'error', 
          {
            timeout: 3000,
            important: true
          }
        );
      })
    }
  },
  data() {
    return {
      majorError: false,
      allowSave: false,
      allowPublish: false,
      editor: null,
      isDirty: false,
      isRichText: true,
      hasChanges: false,
      templates: [],
      restored: false,
      title: this.prename,
      content: this.precontent,
      autosave_seconds: 120000 // Every 2 minutes
    }
  }
}
</script>