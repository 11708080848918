<template>
  <div :class='{"process-in-action-container": true, "process-in-action-container--full": !showSplitLayout}'>
    <div class='procedure-steps'>
      <div class='procedure-steps__header'>
        <header v-if='!turn_off_completion'>
          <h1>Progress</h1>
          <h2>{{procedure.completion_percentage}}</h2>
        </header>
        <div class='procedure-steps-progression-container' v-if='!turn_off_completion'><span :style='{"width": procedure.completion_percentage}'></span></div>
      </div>
      <div class='procedure-steps__steps'>
        <div  :class='{"procedure-steps__step": true, "procedure-steps__step--two": true, "procedure-steps__step--active": index == openProcessView}' v-for='(task, index) in procedure.procedure_tasks_attributes' v-if='task.name.length > 0'>
          <input type='checkbox' v-if='!turn_off_completion' :checked='task.completed' @input="check(task.id, index, task.completed)">
          <div v-if='turn_off_completion'></div>
          <h1><a href='#'  @click.prevent.stop='toggleOpenProcessView(index)'>{{task.name}}</a></h1>
        </div>
      </div>
    </div>
    <div class='procedure__blank' v-show='procedure.procedure_tasks_attributes.length == 0'>No steps yet. Head to Admin Mode and get stuff done!</div>
    <main>
      <div v-if='openTask && openTask.name.length > 0' class='procedure'>
        
        <div class='procedure__title-bar'>
          <h1 class='procedure__title'>{{openTask.name}}</h1>
        </div>

        <div>
          <div class='process__actioner' v-if='!turn_off_completion && openTask.completed'>
            <i class='fas fa-check'></i> 
            <article v-if='openCompletionEdit != openProcessView'>
              <a href='#' v-if='openTask.completion_notes' @click.prevent.stop='toggleOpenCompletionEdit(openProcessView, openTask.completion_notes)' class='process__actioner__notes' v-html='openTask.completion_notes'></a>
              <a href='#' v-if='!openTask.completion_notes' @click.prevent.stop='toggleOpenCompletionEdit(openProcessView, openTask.completion_notes)'>Write a completion note...</a>
              <div class='procedure__complete'>Completed on {{openTask.completed_date}} by {{openTask.user_name}}
              </div>
            </article>
            <form @submit.prevent.stop='submitCompletionNote(openTask.id, openProcessView)' class='process__actioner__form' v-if='openCompletionEdit === openProcessView'>
              <textarea :id="'completionEdit' + openProcessView" v-model='newCompletionNote' placeholder='Your note here...'></textarea>
              <input @click.prevent.stop='submitCompletionNote(openTask.id, openProcessView)' class='button button--small button--primary' type='submit' value='Update Completion Note'>
            </form>
          </div>
          
        </div>

        <div class='procedure__open ck-content' v-if='openTask.description'>
          <div class='rich_text_content' v-html='openTask.description'></div>

          
        </div>
        <div class='procedure__open__complete' v-if='!turn_off_completion'>
          <a v-if='!openTask.completed' class='button button--success button--large'  @click.prevent.stop="check(openTask.id, index, openTask.completed)"><i class='fas fa-check'></i>Mark as Completed</a>
          <a v-if='openTask.completed' class='button button--secondary button--large' @click.prevent.stop="check(openTask.id, index, openTask.completed)">Unmark as Completed</a>
        </div>
        
        
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters } from 'vuex'

export default {
  props: ["url", "can", "turn_off_completion", "id", "rate", "percentage"],
  components: {
  },
  data: function () {
    return {
     newCompletionNote: ""
    }
  },
  computed: {
    ...mapState("procedures", {
      'procedure': 'procedure',
      'openProcessView': 'openProcessView',
      'showSplitLayout': 'showSplitLayout',
      'openCompletionEdit': 'openCompletionEdit',
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength',
      'openTask': 'openTask'
    })
  },
  mounted () {
    
  },
  methods: {
    submitCompletionNote(id, index) {
      this.$store.dispatch('procedures/submitCompletionNote', [id, this.newCompletionNote]);
      this.newCompletionNote = ""
      this.toggleOpenCompletionEdit(index)
    },
    toggleOpenProcessView (index) {
      this.$store.dispatch('procedures/toggleOpenProcessView', index);
    },
    toggleOpenCompletionEdit (index, newCompletionNote) {
      this.newCompletionNote = newCompletionNote
      this.$store.dispatch('procedures/toggleOpenCompletionEdit', index);
      this.$nextTick(() => document.querySelector("#completionEdit" + index).focus())
    },
    check (id, index, completed) {
      this.$store.dispatch('procedures/check', [id, index, completed]);
    }
  }
}
</script>