
<template>
  <div>
    <a href='#' @click.prevent.stop='toggleShow' class='button--large newkb__sidebar__buttons--button button button--success'><i class='fas fa-plus'></i>New...</a>
    <div class='template_chooser_wrapper' v-if='isShown'>
      <div class='template_chooser'>
        <div class='template_chooser__start'>
          <nav>
            <a href='#' @click.prevent.stop='changeView(0)' :class='{"selected": view == 0}'>
              <i class='fal fa-book-reader'></i>New Blank Article
            </a>
            <a href='#' @click.prevent.stop='changeView(1)' :class='{"selected": view == 1}'>
              <i class='fal fa-folder'></i>New Folder
            </a>
            <a href='#' @click.prevent.stop='changeView(3)' :class='{"selected": view == 3}'>
              <i class='fal fa-file-upload'></i>New PDF Upload
            </a>
            <a href='#' v-if='has_article_templates' @click.prevent.stop='changeView(2)' :class='{"selected": view == 2}'>
              <i class='fal fa-shapes'></i>New Article from Template
            </a>
          </nav>
          <a href='#' class='close' @click.prevent.stop='isShown = false'><i class='fas fa-times'></i></a>
        </div>
        <template v-if='view == 0'>
          <div class='template_chooser__blank'>
            <slot name='article'></slot>
          </div>
        </template>
         <template v-if='view == 1'>
          <div class='template_chooser__blank'>
            <slot name='folder'></slot>
          </div>
        </template>
        <template v-if='view == 3'>
          <div class='template_chooser__blank'>
            <form class='form'>
              <h2>Upload a PDF File</h2>
              <div class='form-group' style='width: 100%'>
                <vue-dropzone ref="myVueDropzone" @vdropzone-file-added='vOnFileAdd' @vdropzone-success='vsuccess' id="dropzone" :options='dropzoneOptions'></vue-dropzone>
              </div>
              <div v-if='canUpload' class='form-group' style='width: 100%;margin: 0;'>
                <label for='fileName'>Name of File</label>
                <input id='fileName' type='text' v-model='dropzoneOptions.params.fileName' ref='fileNameInput' />
              </div>
              
              <a href='#' v-if='canUpload' @click.prevent.stop='vTriggerUpload' class='button button--success button--large'>Upload PDF</a>
            </form>
          </div>
        </template>
        <template v-if='view == 2'>
          <div class='template_chooser__templates'>
            <header class='template_chooser__header'>
              <template v-if='!loading'>
                <form @submit >
                  <input type='text' v-model='name' required autofocus placeholder="Enter a name..." ref='templateChooserInput'>
                  <button type='submit' class='button button--success button--large' @click.prevent.stop='save'><i class='fas fa-plus'></i>Start from this Template</button>
                </form>
              </template>
              <loader v-if='loading'></loader>
            </header>
            <div class='template_chooser__filter'>
              <a href='#' :class='{"selected": setFilter == "abc_asc"}' @click.prevent.stop='changeSetFilter("abc_asc")'>Abc</a>
              <a href='#' :class='{"selected": setFilter == "pop"}' @click.prevent.stop='changeSetFilter("pop")'>Most Popular</a>
            </div>
            <template v-if='setFilter == "pop"'>
              <a href='#' :class='{"template_chooser__template": true, "selected": selectedTemplateId == template.id}' @click.prevent.stop='chooseTemplate(template.id, index )' v-for='(template,index) in pop_article_templates'>
                <h1>{{template.name}}</h1>
              </a>
            </template>
            <template v-if='setFilter == "abc_asc"'>
              <a href='#' :class='{"template_chooser__template": true, "selected": selectedTemplateId == template.id}' @click.prevent.stop='chooseTemplate(template.id, index)' v-for='(template, index) in asc_article_templates'>
                <h1>{{template.name}}</h1>
              </a>
            </template>
          </div>
          <div class='template_chooser__content' v-if='setFilter == "abc_asc"'>
            <h1 class='template_chooser__content__title'>{{asc_article_templates[this.selectedTemplateIndex].name}}</h1>
            <div v-html='asc_article_templates[this.selectedTemplateIndex].content'></div>
          </div>
          <div class='template_chooser__content' v-if='setFilter == "pop"'>
            <h1 class='template_chooser__content__title'>{{pop_article_templates[this.selectedTemplateIndex].name}}</h1>
            <div v-html='pop_article_templates[this.selectedTemplateIndex].content'></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import vClickOutside from 'v-click-outside'
import vue2Dropzone from 'vue2-dropzone'
import Loader from './loader.vue'
export default {
  props: ["asc_article_templates", "pop_article_templates", "url", "has_article_templates", "upload_path"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Loader,
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      fileName: "",
      dropzoneOptions: {
          url: this.upload_path, 
          timeout: 90000,
          parallelUploads: 1,
          maxFiles: 1,
          acceptedFiles: "application/pdf",
          maxFilesize: this.max_file_size,
          autoProcessQueue: false, 
          params: {
            fileName: ""
          }
      },
      view: 0,
      canUpload: false,
      name: "",
      isShown: false,
      loading: false,
      selectedTemplateId: 0,
      selectedTemplateIndex: 0,
      setFilter: 'abc_asc'
    }
  },
  mounted ()  {
    if (this.asc_article_templates[0]) {
      this.name = this.asc_article_templates[0].name
      this.selectedTemplateId = this.asc_article_templates[0].id
    }
  },
  methods: {
    vOnFileAdd ( file, response) {
      var that = this;
      this.dropzoneOptions.params.fileName = file.name;
      this.canUpload = true
      this.$nextTick(() => this.$refs['fileNameInput'].focus())
    },
    vsuccess (file, response) {
      window.location = response.url
    },
    vTriggerUpload () {
      this.$refs.myVueDropzone.processQueue()
    },
    save () {
      this.loading = true

      var that = this;

      that.$api.post(that.url,  { name: that.name, article_template_id: that.selectedTemplateId}).then(function (response) {

        setTimeout(function () {
          that.loading = false;
          window.location = response.data.url;
        }, 1000);
        
      }).catch(error => {
        that.loading = false;
      })
    },
    changeView(view) {
      this.view = view;

      if (view == 0) {
        this.$nextTick(() => document.querySelector("#article_name").focus())
      } else if (view == 1) {
        this.$nextTick(() => document.querySelector("#folder_name").focus())
      } else if (view == 2) {
        this.$nextTick(() => this.$refs['templateChooserInput'].focus())
      }
    },
    changeSetFilter(value) {
      this.setFilter = value;
      this.selectedTemplateIndex = 0
      if (this.setFilter == "abc_asc") {
        this.selectedTemplateId = this.asc_article_templates[0].id
      } else {
        this.selectedTemplateId = this.pop_article_templates[0].id
      }
    },
    chooseTemplate(id, index) {
      this.selectedTemplateId = id
      this.selectedTemplateIndex = index
      this.name = this.asc_article_templates[index].name
    },
    toggleShow () {
      this.isShown = !this.isShown;
      this.$nextTick(() => document.querySelector("#article_name").focus())
    },
    onClickOutside (event) {
      this.isShown = false
    },
  },

}
</script>

