<template>
  <div style='position: relative;' class='otp-input'>

    <input type='hidden' :value='value' :name='name'>
    <input type='hidden' :value='isOtpEdit' name='otp_changed'>
    <div class='otp-inputer'>
      <section>
        <h1>Enter secret key</h1>
        <p>Must be at least 16 characters and use Base32 formatting</p>
        <template v-if='!prevalue || isOtpEdit'>
          <div class='inputer'>
            <input :id='id' type='text' minlength="16" placeholder='Enter text-based OTP secret key' :value="value" @keydown.space.prevent @input="format($event)">
            <aside>
              <a href='#' @click.prevent.stop="clear"><i class='fas fa-trash icon'></i>Clear</a>
            </aside>
          </div>
        </template>
        <template v-if='prevalue && !isOtpEdit'>
          <div class='button-group'>
            <a href='#' @click.prevent.stop='isOtpEdit = true' class='button button--secondary'>Change OTP</a>
          </div>
        </template>
      </section>
      <template v-if='!prevalue || isOtpEdit'>
        <div v-if='!isShown'>
          <a href='#' @click.prevent.stop='toggle'>Scan a QR code to fill the secret...</a>
        </div>
        <div v-if='isShown'>
          <h1>Upload QR code</h1>
          <input type='file' accept="image/x-png" id='qrInput' />
          <div class='button-group'>
            <a href='#' class='button button--small button--secondary' @click.prevent.stop='scan'>Scan and fill secret key</a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import jsQR from "jsqr";
import png from "png.js";

export default {
  props: ["name", "prevalue", "id"],
  methods: {
    choose (id, name) {
      this.chosen = {id: id, name}
      this.isShown = false;
    },
    format (e) {
      this.value = e.target.value.toUpperCase().replace(/\s/g, '');;
    },
    clear () {
      this.value = ""
    },
    toggle () {

      this.isShown = true

      this.$nextTick(function () {
        var that = this;
        const fileReader = new FileReader();
        const fileInput = document.getElementById("qrInput");

        fileReader.onload = function(event) {
          const pngReader = new png(event.target.result);
          pngReader.parse(function(err, pngData) {
            if (err) throw err;
            const pixelArray = that.convertPNGtoByteArray(pngData);
            that.qrData = jsQR(pixelArray, pngData.width, pngData.height).data;
          });
        };

        fileInput.onchange = function() {
          fileReader.readAsArrayBuffer(fileInput.files[0]);
        };
      });
    },
    convertPNGtoByteArray (pngData) {
      const data = new Uint8ClampedArray(pngData.width * pngData.height * 4);
      for (let y = 0; y < pngData.height; y++) {
        for (let x = 0; x < pngData.width; x++) {
          const pixelData = pngData.getPixel(x, y);

          data[(y * pngData.width + x) * 4 + 0] = pixelData[0];
          data[(y * pngData.width + x) * 4 + 1] = pixelData[1];
          data[(y * pngData.width + x) * 4 + 2] = pixelData[2];
          data[(y * pngData.width + x) * 4 + 3] = pixelData[3];
        }
      }
      return data;
    },
    scan () {
      var regex = /[?&]([^=#]+)=([^&#]*)/g,
        url = this.qrData,
        params = {},
        match;
      while(match = regex.exec(url)) {
        params[match[1]] = match[2];
      }
      if (params.secret) {
        this.value = params.secret
      } else {
        alert("Could not extract OTP secret!")
      }
    }
  },
  data () {
    return {
      file: "",
      isShown: false,
      isOtpEdit: false,
      qrData: "",
      value: ""
    }
  }
}
</script>

