<template>
  <div class='procedure__toolbar'>

    <div class='button-group' v-if='can'>
      <template v-if='!showEdit'>

        <a href='#' v-if='!showDescription && procedure.description' @click.prevent.stop='toggleDescription' class='button button--small button--secondary'><i class='fas fa-info-circle'></i>Info</a>

        <a href='#' v-if='showDescription && procedure.description' @click.prevent.stop='toggleDescription' class='button button--small button--secondary'><i class='fas fa-info-circle'></i>Hide Info</a>

        <template v-if='!turn_off_completion'>
          <a href='#' v-if='procedure.completed > 0' class='button button--small button--secondary' @click.prevent.stop='copyCompletedTasks'><i class='fas fa-copy'></i>Copy Completed Tasks ({{procedure.completed}})</a>
          <slot></slot>
        </template>

        <a href='#' @click.prevent.stop='toggleSplitLayout' class='button button--small button--secondary'><i class='fas fa-columns'></i>Toggle Split Layout</a>

      </template>

    </div>

    <div class='procedure__toolbar-final' v-if='can'>
      <a href='#' v-if='!showEdit' @click.prevent.stop='toggleEdit' class='button button--secondary button--small'><i class='fas fa-edit'></i>Edit Tasks</a>
      <a href='#' v-if='showEdit' @click.prevent.stop='toggleEdit' class='button button--secondary button--small'><i class='fas fa-times'></i>Close</a>
    </div>


    <div v-if='!can'></div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

export default {
  props: ["can", "turn_off_completion"],
  computed: {
    ...mapState("procedures", {
      'showEdit':  'showEdit',
      'showBulk': 'showBulk',
      'showSort': 'showSort',
      'showSplitLayout': 'showSplitLayout',
      'procedure': 'procedure',
      'showDescription': 'showDescription',
      'openProcessView': 'openProcessView',
      'openProcessEdit': 'openProcessEdit',
      'openCompletionEdit': 'openCompletionEdit',
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength'
    }),
    name: {
      get() {
        return this.$store.state.procedures.procedure.name
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureName', value)
      }
    }, 
    description: {
      get() {
        return this.$store.state.procedures.procedure.description
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureDescription', value)
      }
    },
    bulk: {
      get() {
        return this.$store.state.procedures.bulk
      },
      set(value) {
        this.$store.dispatch('procedures/updateBulk', value)
      }
    },
    procedure_tasks_attributes: {
      get() {
        return this.procedure.procedure_tasks_attributes
      },
      set(value) {
        this.$store.dispatch('procedures/update_tasks', value)
      }
    }
  },
  methods: {
    toggleEdit () {
      this.$store.dispatch('procedures/toggleEdit');
    },
    toggleDescription () {
      this.$store.dispatch('procedures/toggleDescription');
    },
    toggleSplitLayout () {
      this.$store.dispatch('procedures/toggleSplitLayout');
    },
    copyCompletedTasks () {

      if (this.procedure.completed == 0) {
        return alert("Complete tasks first!")
      }
      var that = this;
      var text = "";
      that.procedure.procedure_tasks_attributes.forEach ( function (item, idx) {
        if (item.completed ) {
          if (item.completion_notes) {
            text += `✔ COMPLETED - ${item.name} - ${item.completed_date}. Completion Notes: ${item.completion_notes}`;
          } else {
            text += `✔ COMPLETED - ${item.name} - ${item.completed_date}`;
          }
           
          if (idx != that.procedure.procedure_tasks_attributes.length - 1) {
            text += "\n";
          }
        }
      })
      Clipboard.copy(text);

      that.flash("Copied!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
      text = "";
    }
  }
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>

