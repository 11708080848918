<template>
  <div>
    <a href='#' class='mkb__sidebartoggle' v-if='isFull' @click.prevent.stop='showInSplit'><i class="fal fa-arrow-left"></i>Condense View</a>
    <a href='#' class='mkb__sidebartoggle' v-if='!isFull' @click.prevent.stop='showInFull'><i class="fal fa-arrow-right"></i>Expand View</a>
  </div>
  
</template>

<script>
export default {
  mounted () {
    this.isFull = JSON.parse(localStorage.getItem('huduArticleSidebarFull'))
    var container = document.querySelector(".mkb__container")

    if (this.isFull == true) {
      container.classList.add("mkb__container--full")
    } else {
      container.classList.remove("mkb__container--full")
    }
  },
  methods: {
    showInFull () {
      this.isFull = true
      localStorage.setItem('huduArticleSidebarFull', true);
      var container = document.querySelector(".mkb__container")
      container.classList.add("mkb__container--full")
    },
    showInSplit () {
      this.isFull = false
      localStorage.setItem('huduArticleSidebarFull', false);

      var container = document.querySelector(".mkb__container")
      container.classList.remove("mkb__container--full")
      
    },
  },
  data() {
    return {
      isFull: false
    }
  }
}
</script>