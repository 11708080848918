<template>
  <div>
    <v-select v-model='users' name='users' placeholder='Search for users...' multiple :options="options"></v-select>
    <input type='hidden' name='users' :value='JSON.stringify(users)'>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  props: ["options"],
  components: {
    vSelect
  },
  data: function () {
    return {
      users: [],
      isShown: false
    }
  }
}
</script>

